<template>
  <v-main :class="desktop ? 'tway-background-curves-svg' : 'tway-background-curves-svg-mobile'">
    <v-container fill-height>
      <v-row class="ma-0" justify="center">
        <v-card
          outlined
          :width="desktop ? '60%' : '100%'"
          style="border-radius: 10px !important"
          :class="{ 'animated shake': loginError }"
        >
          <v-form>
            <grid
              :class-left="
                (desktop ? 'registration-form-border-right' : '') + ' pa-5 py-md-6 px-md-8 py-lg-10 px-lg-14'
              "
              class-right="pa-5 py-md-6 px-md-8 py-lg-14 px-lg-14"
              align-row="center"
            >
              <template v-slot:alone>
                <v-row class="pa-0 ma-0" :class="desktop ? 'pa-14' : 'pa-4'" justify="center">
                  <span class="px-2 text-h5 text-md-h4 mb-4">Recuperar Contraseña</span>
                  <v-form>
                    <p v-if="!sent" class="ml-2 mt-2 text-center">
                      Para recuperar tu contraseña escribe tu correo corporativo y te enviaremos los pasos a seguir.
                    </p>
                    <p v-if="sent" class="ml-2 mt-2 text-center">
                      ¡Genial! Te enviamos un correo a tu casilla. Revísalo y sigue las instrucciones.
                    </p>
                    <div v-show="!sent">
                      <v-text-field
                        label="Escribe aquí tu correo corporativo"
                        name="email"
                        type="text"
                        v-model="email"
                        :rules="[required('email'), validateEmail()]"
                        required
                      />
                    </div>
                  </v-form>

                  <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="ma-0 pa-0">
                      <v-row v-show="sent" class="ma-0 pa-0" justify="center">
                        <v-img
                          :src="require('@/assets/img/email sent.svg')"
                          class="rounded mb-8"
                          contain
                          max-width="50%"
                        />
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="ma-0 pa-0 mb-8 mt-8">
                      <v-row class="ma-0 pa-0" justify="center">
                        <v-btn
                          depressed
                          rounded
                          :disabled="sent"
                          class="button-text white--text"
                          color="tway-violet"
                          @click="sendEmail"
                        >
                          <span v-if="!sent">recuperar contraseña</span>
                          <span v-else class="px-8">listo</span>
                        </v-btn>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="ma-0 pa-0">
                      <v-row class="ma-0 pa-0" justify="center">
                        <router-link class="tway-violet--text" :to="{ name: 'login' }"> Volver al inicio </router-link>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-row>
              </template>
            </grid>
          </v-form>
        </v-card>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ValidatorsService from "@/services/validatorsService";
import LoginService from "@/services/loginService";
import RowTwo from "@/components/grid/RowTwo.vue";

export default {
  name: "PasswordRecoverForm",
  data() {
    return {
      loginError: false,
      email: null,
      sent: false,
    };
  },
  components: {
    grid: RowTwo,
  },
  methods: {
    required: (propertyType) => ValidatorsService.required(propertyType),
    validateEmail: () => ValidatorsService.validateEmail(),

    sendEmail() {
      let email = this.email;
      this.$recaptcha("login").then((token) => {
        LoginService.sendPasswordRecoveryEmail(email, token)
          .then(() => {
            //this.$router.push({ name: 'login' })
            this.sent = true;
          })
          .catch((err) => {
            this.sent = true;
            this.$log.error(err);
          });
      });
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
